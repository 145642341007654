import { Button, Form, Input, Select, Tabs } from 'antd';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PassengerForm from "./PassengerForm"
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { CaretLeftOutlined, CaretRightOutlined, CheckOutlined, ExclamationOutlined } from '@ant-design/icons'
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import './passenger.css';
import "../common.css"
import { useTranslation } from 'react-i18next';

const Passenger = ({ setSubmittable, trigger }) => {
    const reserved = useSelector(state => state.cart.reserved);
    const [selectedTab, setSelectedTab] = useState(0);
    const passengers = useSelector(state => state.cart.passengers);
    const items = [];
    const {t} = useTranslation()
    function validate(index) {
        try {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(passengers[index].email)
                && isPossiblePhoneNumber(passengers[index].phone, parsePhoneNumber(passengers[index].phone).country) && isValidPhoneNumber(passengers[index].phone, parsePhoneNumber(passengers[index].phone).country)
                && !Object.values(passengers[index]).some(item => item === null || item === '' || item === undefined)) {
                return (true)
            }
            return (false)
        } catch (e) { console.log(e) }
    }

    const checkValidity = (index) => {
        if (trigger & !validate(index)) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (items.every((item => item.isValid === true))) {
            setSubmittable(true)
        } else setSubmittable(false)
    }, [items])

    if (!reserved.length) return (<h3 className='text-center'>
        {/* No seats selected ! */}
        {t("noSeats")}
    </h3>);


    reserved.map((item, index) => items.push({
        key: index,
        label: (<span className="pass-tab-item"> {item.seat.category.substring(0, 3).toUpperCase() + ' ' + 'Seat: ' +
            item.seat.number} {validate(index) && <span className="pass-tab-tick"><CheckOutlined /></span>} {checkValidity(index) && <span className="pass-tab-tick bg-danger"><ExclamationOutlined /></span>} </span>),
        children: <PassengerForm item={item} trigger={trigger} />,
        isValid: validate(index) ? true : false
    }));



    const OperationsSlot = {
        left:
            <Button
                className='common-arrow-btn d-flex justify-content-center align-items-center'
                onClick={() => {
                    if (selectedTab > 0)
                        setSelectedTab(selectedTab - 1)
                }}
            >
                <CaretLeftOutlined />
            </Button>,
        right:
            <Button
                className='common-arrow-btn d-flex justify-content-center align-items-center'
                onClick={() => {
                    if (selectedTab < reserved.length - 1)
                        setSelectedTab(selectedTab + 1)
                }}
            >
                <CaretRightOutlined />
            </Button>,
    };
    return (
        <Tabs
            rootClassName={passengers.length < 4 ? 'passenger-info-block' : 'passenger-info-block custom-pass-margin'}
            activeKey={selectedTab}
            centered={passengers.length < 4}
            tabBarExtraContent={OperationsSlot}
            onTabClick={(aa) => {
                setSelectedTab(aa)
            }}
            defaultActiveKey={1}
            items={items}
        />
    )
}
export default Passenger;
