import React from 'react';
import { LANGUAGES } from "../../constants";
import { NavDropdown, Navbar, Row, Col, Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Ticket } from '../../assets/img/ticket.svg';
import Logo from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';

const lang = {
    ru: require('../../assets/img/russia-flag.png'),
    ro: require('../../assets/img/romania-flag.png'),
    en: require('../../assets/img/uk-flag.png')
}

const Header = () => {

    const { i18n, t } = useTranslation();
    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        localStorage.setItem('i18nextLng', code.toLowerCase().toString())
    }
    const titleLang = LANGUAGES.filter(lang => lang.code === i18n.resolvedLanguage);
    return (
        <>
            <Navbar bg="light" data-bs-theme="light" style={{ padding: '15px 0' }}>
                <Container>
                    <Row style={{ width: '100%' }} className='align-items-center'>
                        <div className='w-100 justify-content-between d-flex'>
                            {/* <Col md={4}> */}
                            <Navbar.Brand href="/">
                                <Image src={Logo} width={180} />
                            </Navbar.Brand>
                            {/* </Col> */}
                            {/* <Col md={8} className='d-flex justify-content-end gap-5' style={{height: "100%"}}> */}
                            <div className='d-flex justify-content-end align-items-center gap-5'>
                                    <Link to={"/resend"} className='resend-link d-inline-flex justify-content-center gap-2 align-items-center text-center'>
                                        <Ticket height={25} />
                                        <span>{t("resendTicket")}</span>
                                        {/* Resend ticket info */}
                                        {/* <span className='main-link-arrow'></span> */}
                                    </Link>

                                <Navbar.Collapse className="justify-content-end align-items-center nav-lang">

                                    <NavDropdown title={
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Image src={lang[i18n.resolvedLanguage.toString()]} width={20} style={{ marginRight: '10px' }} />
                                            <div>{titleLang[0].label}</div>
                                        </div>
                                    } id="basic-nav-dropdown" align="start">
                                        {LANGUAGES.map(({ code, label }) => (
                                            <NavDropdown.Item
                                                className='nav-lang-item'
                                                key={code}
                                                onClick={() => {
                                                    changeLanguage(code)
                                                }}
                                            >
                                                <Image src={lang[code.toString()]} width={20} />
                                                {label}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                </Navbar.Collapse>
                            </div>
                            {/* </Col> */}
                        </div>
                    </Row>
                </Container>
            </Navbar>
        </>
    );
};
export default Header;
