import { Container } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import StripeCheckoutForm from "./StripeCheckoutForm";
import Loader from "../loader/Loader";

const stripePromise = loadStripe('pk_test_FQRkJ2sDhk0hjqOfSpsmoMcc');


const StripeProcessing = () => {

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );
    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#ee703d',
            colorText: '#094267',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <>
            <Container className="d-none">
                <Elements options={options} stripe={stripePromise}>
                    <StripeCheckoutForm />
                </Elements>
            </Container>
            {/*<Loader loading={true} />*/}
        </>);
}
export default StripeProcessing;
