import React, { useEffect, useState } from 'react';
import Seo from '../seo/Seo';
import { Container, Col, Row, Image } from 'react-bootstrap';

import SearchBox from "../searchbox/SearchBox";
import SearchBoxMainTickets from "../searchbox/SearchBoxMainTickets";
import { useDispatch, useSelector } from "react-redux";
import SearchItem from "./SearchItem";
import { resetCart, setStatus } from "../../slices/search";
import { Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Search = () => {

    const dispatch = useDispatch();
    const results = useSelector(state => state.search.results);
    const searchStatus = useSelector(state => state.search.status);
    const itemFrom = useSelector(state => state.search.from);
    const itemTo = useSelector(state => state.search.to);
    // const itemFrom = parseInt(useLocation().pathname.split("/")[2].split("_")[0])
    // const itemTo = parseInt(useLocation().pathname.split("/")[3].split("_")[0])
    const [loading, setLoading] = useState(true);
    const locations = useSelector((state) => state.locations.locations);
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [titleFrom, setTitleFrom] = useState('');
    const [titleTo, setTitleTo] = useState('');

    useEffect(() => {
        setLoading(false);
    }, [itemTo, itemFrom])

    useEffect(() => {
        const onClickSearch = () => {
            const departure = locations.map((loc) => { let notNullCities = loc.cities.filter((city) => city.id == itemFrom); return notNullCities && notNullCities[0]?.name })
            const arrival = locations.map((loc) => { let notNullCities = loc.cities.filter((city) => city.id == itemTo); return notNullCities && notNullCities[0]?.name })
            const dep = departure.filter((dep) => dep !== undefined);
            const arr = arrival.filter((arr) => arr !== undefined);
            setTitleTo(arr[0]);
            setTitleFrom(dep[0]);
            const departureToSend = dep[0]?.replace(/^[, ]+|[, ]+$|[, ]+/g, "_").trim();
            const arrivalToSend = arr[0]?.replace(/^[, ]+|[, ]+$|[, ]+/g, "_").trim();
            if (!itemTo || !itemFrom) {
                return;
            }
            else {
                dispatch(setStatus('search'))
                navigate(`/search/${itemFrom + "_" + departureToSend}/${itemTo + "_" + arrivalToSend}`)
            }
        }
        onClickSearch()
    }, [itemFrom, itemTo])

    useEffect(() => {
        dispatch(resetCart())
    }, [])

    useEffect(() => {
        setLoading(true);
        if (searchStatus === 'search' || searchStatus === 'succeeded') {
            setTimeout(() => setLoading(false), 1000);
        }
    }, [searchStatus])

    return (
        <>
            <div className='search-result-page'>
                <Container>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            {/*<SearchBox />*/}
                            <SearchBoxMainTickets />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='search-result-item'>
                <Container>
                    {itemFrom && itemTo ? (
                        <>
                            {loading === true
                                ? <div className='common-spin-container'><Spin size="large" spinning={loading} /></div>
                                : (
                                    <>
                                        <Seo title={`Search course from ${titleFrom} to ${titleTo} page React Helmet!`}
                                             description='Search page React Helmet.'
                                             name='VBus'
                                             type='article'
                                        />
                                        {results.length > 0 ?
                                            (
                                                <>
                                                    <div className='result-count-style'>
                                                        {/* Search results: */}
                                                        {t("searchResults")}:{" "} 
                                                        <b>{results.length}</b>
                                                        </div>
                                                    {results.map(item => <SearchItem key={item.departureTripId} item={item} />)}
                                                </>
                                            ) : <h2 className='text-center mt-3'>
                                                {/* No search results */}
                                                {t("noSearch")}
                                                </h2>
                                        }
                                    </>
                                )
                            }
                        </>
                    ) : <h2 className='text-center mt-3'>
                        {/* Select direction */}
                        {t("selectDir")}
                        </h2>}
                </Container>
            </div>
        </>
    );

}
export default Search;
