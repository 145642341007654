import {useEffect, useRef, useState} from "react";

const BusObjectTableView = (props) => {
    const [size, setSize] = useState({ width: 0, height: 0 })
    const table = useRef();
    const flexDirection = (props.direction === "left" || props.direction === "right") ? "row" : "column"
    const count = Math.round((props.direction === "left" || props.direction === "right") ? size.width / size.height : size.height / size.width)
    const internStyle = (props.direction === "left" || props.direction === "right") ? { width: 1 } : { height: 1 };
    useEffect(() => {
        setSize({ width: table.current.clientWidth, height: table.current.clientHeight })
    }, [])
    return (
        <div
            ref={table}
            className={'BusObjectTableView'}
            style={{ flexDirection: flexDirection, backgroundColor: "#4A4C52", justifyContent: "space-evenly", borderRadius: 5, ...props.style}}
        >
            {count > 1 ?
                Array.from(Array(count - 1)).map((_, index) => <div key={index} style={{ backgroundColor: "#6A6B71", ...internStyle }} />)
                :
                null
            }
        </div>
    )
}
export default BusObjectTableView;
