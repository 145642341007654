import {useCallback} from "react";
import BusObjectWindShieldView from "./objects/BusObjectWindShieldView";
import BusObjectDashboardView from "./objects/BusObjectDashboardView";
import BusObjectDriverView from "./objects/BusObjectDriverView";
import BusObjectSeatView from "./objects/BusObjectSeatView";
import BusObjectExitView from "./objects/BusObjectExitView";
import BusObjectStairsView from "./objects/BusObjectStairsView";
import BusObjectTableView from "./objects/BusObjectTableView";
import BusObjectZoneView from "./objects/BusObjectZoneView";

import icon_bus_kitchen from "../../assets/bus/icon_bus_kitchen@2x.png"
import {useDispatch, useSelector} from "react-redux";
import {
    addPassenger,
    addSeat,
    fetchUsed,
    removePassenger,
    removeReserve,
    removeSeat,
    reserveSeat,
    unreserveSeat
} from "../../slices/cart";

const availableCategories = ['premium', 'disability', 'standart']
const RenderObject = (busObject, width, height) => {
    const dispatch = useDispatch();
    const selectedSeats = useSelector(state => state.cart.seats);
    const reserved = useSelector(state => state.cart.reserved);
    const blockedSeatIds = useSelector(state => state.cart.usedSeats)
    const trip = useSelector(state => state.cart.trip)

    const style ={
        position: "absolute",
        left: (busObject.position.x ?? 0) * (100 / width) + "%",
        top: (busObject.position.y ?? 0) * (100 / height) + "%",
        width: (busObject.position.width ?? 0) * (100 / width) + "%",
        height: (busObject.position.height ?? 0) * (100 / height) + "%",
        display: 'flex'
    }
    const bookSeat = async (value) => {
        try {

            let findIndex = selectedSeats.findIndex(el => el.id === value.id)
            if( findIndex < 0)
            {
                const originalBlockedSeats =  await dispatch(fetchUsed({
                    routeId:trip.routeId,
                    departureTripId: trip.departureTripId,
                    arrivalTripId: trip.arrivalTripId
                })).unwrap()
                if(originalBlockedSeats.includes(value.id)) {
                    return;
                }

               let reservedOriginal = await  dispatch(reserveSeat({
                    tickets: [
                        {
                            routeId:trip.routeId,
                            departureTripId: trip.departureTripId,
                            arrivalTripId: trip.arrivalTripId,
                            seatId: value.id
                        }
                    ]
                })).unwrap();
                dispatch(addSeat(value))
                let tickedId = reservedOriginal.find(el => el.seatId === value.id)
                dispatch(addPassenger(tickedId.id))
            }
            else
            {
                let tickedId = reserved.find(el => el.seatId === value.id)
                dispatch(unreserveSeat({
                   tickets: [{ ticketId:tickedId.id }]
                })).then(() => {
                    dispatch(removeReserve({id:tickedId.id}))
                    dispatch(removePassenger({id:tickedId.id}))
                    dispatch(fetchUsed({
                        routeId:trip.routeId,
                        departureTripId: trip.departureTripId,
                        arrivalTripId: trip.arrivalTripId
                    })).then(() => {
                        dispatch(removeSeat({index: findIndex}))
                    })
                })

            }
        } catch (rejectedValueOrSerializedError) {

        }
    }
    switch (busObject.type) {
        case "wind-shield":
            return <BusObjectWindShieldView key={`bus_object_${busObject.id}`} style={style} />
        case "dashboard":
            return <BusObjectDashboardView key={`bus_object_${busObject.id}`} style={style} />
        case "driver":
            return <BusObjectDriverView key={`bus_object_${busObject.id}`} style={style} />
        case "seat":
            let status = availableCategories.includes(busObject.category ?? "") ? "available" : "unavailable";
            if(blockedSeatIds.includes(busObject.id) && !selectedSeats.find(el => el.id === busObject.id) ) status = 'booked'
            //const status = blockedSeatIds.includes(busObject.id ?? 0) ? "booked" : (availableCategories.includes(busObject.category ?? "") ? "available" : "unavailable")
            return <BusObjectSeatView key={`bus_object_${busObject.id}`} style={style} busSeatObject={busObject} status={status}  onPress={() => { bookSeat(busObject)}} selected={selectedSeats.find(el => el.id === busObject.id)} />
        case "exit":
            return <BusObjectExitView key={`bus_object_${busObject.id}`} style={style} direction={busObject.direction} />
        case "stairs":
            return <BusObjectStairsView key={`bus_object_${busObject.id}`} style={style} direction={busObject.direction} />
        case "table":
            return <BusObjectTableView key={`bus_object_${busObject.id}`} style={style} direction={busObject.direction} />

        case "toilet":
            return <BusObjectZoneView key={`bus_object_${busObject.id}`} style={style} text={busObject.text ?? "WC"} />
        case "kitchen":
            return <BusObjectZoneView key={`bus_object_${busObject.id}`} style={style} icon={icon_bus_kitchen} text={busObject.text} />
        case "wall":
            return <BusObjectZoneView key={`bus_object_${busObject.id}`} style={style} text={busObject.text} />
        default:
            return <BusObjectZoneView key={`bus_object_${busObject.id}`} style={style} text={busObject.text} />
    }
}

const BusFloor = (props) => {
    const size = useCallback(() => {
        const width  = Math.max.apply(null,  props.floor.objects.map((object) => (object.position.x ?? 0) + (object.position.width ?? 0)))
        const height  = Math.max.apply(null,  props.floor.objects.map((object) => (object.position.y ?? 0) + (object.position.height ?? 0)))
        return { width: width, height: height }
    }, [props.floor])
    return (
        <div style={{
            backgroundColor: "#2F313D",
            borderColor: "#808080",
            border: '5px solid',
            width:'350px',
            position: 'relative',
            padding: 5,
            overflow: "hidden",
            margin: '0 auto',
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
        }}>
            <div style={{
                aspectRatio: size().width / size().height,
                width: "100%",
                overflow: "hidden",

                borderTopLeftRadius: 35,
                borderTopRightRadius: 35,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
            }}>
                {props.floor.objects.map((object) => RenderObject(object, size().width, size().height))}
            </div>
        </div>
    );
}
export default BusFloor;
