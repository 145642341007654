import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

import SliderVideo from '../../assets/video/scorpions-vanessa-mae.mp4'

// Import Swiper styles
import 'swiper/css';
import './sliderMain.css';

const SliderMain = () => {
    return (
        <>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                <SwiperSlide>
                    <video width='100%' muted autoPlay loop >
                        <source src={SliderVideo} type="video/mp4" />
                        Sorry, your browser doesn't support videos.
                    </video>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
export default SliderMain;
