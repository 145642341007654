import IconTint from "react-icon-tint";
import image_bus_object_exit from "../../../assets/bus/image_bus_object_exit@2x.png";

const BusObjectZoneView = (props) => {
    return (
        <div className={'BusObjectZoneView'} style={{ backgroundColor: "#4A4C52", borderColor: "#2F313D", alignItems: "center", justifyContent: "center", borderWidth: 1, ...props.style}}>
            {props.icon ?
                <IconTint src={props.icon} color={props.iconColor ?? "#2F313D" } style={{ width: 'fit-content' }} />
                :
                null
            }
            {props.text ?
                <span style={{ color: props.iconColor ?? "#2F313D", fontSize: 24, fontWeight: "bold" }}>{props.text}</span>
                :
                null
            }
        </div>
    )
}
export default BusObjectZoneView;
