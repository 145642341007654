import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useSelector, useDispatch } from 'react-redux';
import { clearArrivals, fetchDeparture, setFrom, setTicketType, setTo } from '../../slices/search';
import SliderMain from "../slider/SliderMain";
import SearchBoxMainTickets from "../searchbox/SearchBoxMainTickets";
import { Radio, Space } from 'antd';
import './main.css';
import { TwitterOutlined, InstagramOutlined, GooglePlusOutlined } from '@ant-design/icons';

import IconApp from '../../assets/img/icon_app.png';
import IconCheckmark from '../../assets/img/icon_checkmark.svg';
import IconFree from '../../assets/img/icon_free.svg';
import IconFriendly from '../../assets/img/icon_friendly.svg';
import IconSafety from '../../assets/img/icon_safely.svg';
import IconSeat from '../../assets/img/icon_seat.svg';
import AppStore from '../../assets/img/appstore.svg';
import GooglePlay from '../../assets/img/googleplay.svg';
import apiService from "../../services/api.service";
import { useTranslation } from 'react-i18next';

const Main = () => {
    const dispatch = useDispatch();
    const selectedSearchType = useSelector(state => state.search.type)
    const itemFrom = useSelector(state => state.search.from)
    const arrivalResult = useSelector(state => state.search.arrivalResult)
    const [countryList, setCountryList] = useState([]);
    const { t } = useTranslation()
    const getNearList = async () => {
        let resp = await apiService.getLocationsListNear();
        setCountryList(resp.data);
        // if(resp.data.length)
        //     dispatch(setFrom(resp.data[0].id));
    }
    useEffect(() => {
        if (!countryList.length) {
            getNearList();
        }
    }, []);
    //const countryList = ['Berlin', 'Munich', 'Hamburg', 'Frankfurt', 'Stuttgart', 'Cologne', 'Düsseldorf', 'Dresden', 'Leipzig', 'Hanover', 'Nuremberg', 'Dortmund', 'Freiburg', 'Karlsruhe', 'Mannheim', 'Heidelberg', 'Essen', 'Bremen', 'Aachen', 'Frankfurt Airport'];

    const setBoxStyle = (event) => {
        const searchBoxStyle = document.querySelector('.search-box-block');

        if (event === 'tickets') {
            searchBoxStyle.style.borderTopRightRadius = '0';
            searchBoxStyle.style.borderBottomRightRadius = '10px';
        } else if (event === 'cargo') {
            searchBoxStyle.style.borderTopRightRadius = '10px';
            searchBoxStyle.style.borderBottomRightRadius = '0';
        }
    }
    useEffect(() => {
        setBoxStyle(selectedSearchType)
    }, [selectedSearchType])

    return (
        <>
            <SliderMain />
            <Container className='style-main-block'>
                <Row>
                    <Col md={9}>
                        <SearchBoxMainTickets />
                    </Col>
                    <Col md={3} className='d-flex justify-content-center' style={{ paddingRight: '0' }}>
                        <Radio.Group
                            className='tickets-button-block'
                            defaultValue={selectedSearchType}
                            buttonStyle="solid"
                            onChange={(event) => {
                                setBoxStyle(event.target.value);
                                dispatch(setTicketType(event.target.value));
                                //console.log('select booking type', event.target.value);
                            }}
                        >
                            <Space direction="vertical" className='justify-content-between' style={{ height: '100%', width: '100%' }}>
                                <Radio.Button className='main-ticket-style ticket-style' value="tickets">
                                    {t("ticketsBooking")}
                                    {/* Tickets Booking */}
                                </Radio.Button>
                                <Radio.Button className='main-ticket-style cargo-style' value="cargo">
                                    {t("cargoBooking")}
                                    {/* Cargo Booking */}
                                </Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col md={6}>
                        <Row>
                            <Col md={12} className='text-center friendly-block-style'>
                                <div className="icon-position">
                                    <Image src={IconFriendly} height={30} />
                                </div>
                                <h6>
                                    {/* Travel environmentally-friendly */}
                                    {t("mainBoxHeader1")}
                                </h6>
                                <p>Our efficient coaches are proven to have an excellent carbon footprint per driven passenger-kilometer.</p>
                                <a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Bus travel and environment <span className='main-link-arrow'></span></a>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className='text-center safety-block-style'>
                                <div className="icon-position">
                                    <Image src={IconSafety} height={30} />
                                </div>
                                <h6>
                                    {/* Health and Safety */}
                                    {t("mainBoxHeader2")}
                                </h6>
                                <p>Keep yourself and others safe while traveling with us.</p>
                                <a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Learn more <span className='main-link-arrow'></span></a>
                            </Col>
                            <Col md={6} className='text-center seat-block-style'>
                                <div className="icon-position">
                                    <Image src={IconSeat} height={30} />
                                </div>
                                <h6>
                                    {/* Comfort on board */}
                                    {t("mainBoxHeader3")}
                                </h6>
                                <p>Our buses are equipped with large and comfortable seats, a toilet, Wi-Fi and power outlets.</p>
                                <a href="#" className='travel-link d-inline-flex justify-content-center align-items-center'>Our onboard service<span className='main-link-arrow'></span></a>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className='banner-block'>
                        <Row>
                            <Col md={12} className='banner-block-style d-flex justify-content-start align-items-start'>
                                {/*<Image src={Banner} width='100%' />*/}
                                <div className='d-flex justify-content-center align-items-start'>
                                    <Image src={IconApp} height='100%' />
                                    <div>
                                        <h4>
                                            {/* Discover all destinations */}
                                            {t("mainBlueBoxHeader")}
                                            </h4>
                                        <p >Choose from over 3,000 <br /> travel destinations <br /> in 40+ countries.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='download-block-style'>
                                <ul className='d-flex justify-content-center align-items-center'>
                                    <li className='d-flex justify-content-center align-items-center'>
                                        <Image src={IconFree} height={40} />
                                        <h5>Download <br /> for free</h5>
                                    </li>
                                    <li className='store-img'>
                                        <a href="#">
                                            <Image src={GooglePlay} width='100%' />
                                        </a>
                                    </li>
                                    <li className='store-img'>
                                        <a href="#">
                                            <Image src={AppStore} width='100%' />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} className='mb-4'>
                    <Masonry>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Get Cheap Bus Tickets Easily with VBus!</h4>
                            <p>If you're looking to travel by bus in your country or even worldwide, VBus has got you covered with our extensive bus routes! With over 400,000 routes worldwide, you can easily find the perfect option for your trip. Plus, our cheap bus tickets make it easy and affordable to get wherever you want to go, whether it's for work or play.</p>
                        </div>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Travelling with VBus is a more sustainable choice</h4>
                            <p>Traveling by bus is not just a convenient choice but also an environmentally responsible one, as bus travel reduces traffic and emissions compared to travelling by car. We’re working to make bus travel even greener with high environmental standards across our fleet of buses, the use of alternative drive and fuel technologies, and the option for all passengers to offset their carbon when buying their bus tickets. So, when you travel with VBus you can know you’re making a responsible choice</p>
                        </div>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Buying Your Bus Tickets is Easy with VBus</h4>
                            <p>Our website and app make it easy to plan your journey, find the cheapest bus fares, and purchase your bus tickets securely. Whether you are planning or booking last-minute trip, VBus makes buying bus tickets a breeze. Plus, with our convenient e-ticket system, you can board your bus without the hassle of paper tickets as your phone will serve as your ticket!</p>
                        </div>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Bus and Train Routes to Suit Your Schedule</h4>
                            <p>Traveling with VBus is simple and hassle-free, thanks to our frequent bus connections, reliable schedules, and modern vehicles. With over 400,000 bus routes across the globe, including Europe and South America, you can easily find the perfect option for your trip. Additionally, VBus offers train travel in Sweden and Germany with Train, providing even more travel options to suit your needs. Our extensive network spans 40+ countries and over 3,000 destinations worldwide, making it easy to plan and book your trip online or through our user-friendly app. Plus, stay connected on the go with free WiFi on most of our buses and trains, and track your journey in real-time with our advanced tracking system.</p>
                        </div>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Travel in Comfort and Style</h4>
                            <p>VBus prioritizes comfort and convenience for your journey. With amenities like on-board WiFi, extra legroom, power outlets and toilets on board, we have thought of everything to make your trip as smooth as possible. Our easy-to-use app and simple booking platform make it effortless to plan your bus travel. Book your bus ticket with VBus today</p>
                        </div>
                        <div className='main-text-info-block'>
                            <div className="icon-position">
                                <Image src={IconCheckmark} height={30} />
                            </div>
                            <h4>Bus and Train Travel Made Easy with VBus</h4>
                            <p>In addition to offering an extensive network of bus routes, VBus also provides train travel with Train. With Train, you can travel across Germany and Sweden comfortably and affordably. Enjoy amenities such as free WiFi, spacious seats, and power outlets on board. Train is committed to sustainable travel, making it an excellent choice for eco-conscious travelers. So whether you prefer to travel by bus or train, VBus has you covered for all your travel needs.</p>
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
            </Container>
            <div className='top-destinations-block'>
                <Container>
                    <Row>
                        <h4 className='text-center'>{t("topDestinations")}</h4>
                        {countryList.map((item, index) =>
                            index <= 19 && (
                                <Col md={3} key={index} className='destinations-list' onClick={async () => {
                                    window.scrollTo(0, 0)
                                    if (itemFrom) {
                                        if (arrivalResult.some((el) => el.id == item.id)) {
                                            dispatch(setTo(item.id))
                                            dispatch(fetchDeparture({ arrivalId: item.id }))
                                        }
                                        else {
                                            dispatch(setFrom(null))
                                            dispatch(clearArrivals([]))
                                            dispatch(setTo(item.id))
                                            dispatch(fetchDeparture({ arrivalId: item.id }))
                                        }
                                    } else {
                                        dispatch(setTo(item.id))
                                        dispatch(fetchDeparture({ arrivalId: item.id }))
                                    }
                                }}>
                                    <a>{item.name}</a>
                                </Col>
                            )

                        )}
                    </Row>
                </Container>
            </div>
            <Container className='find-us-block'>
                <Row>
                    <Col md={3}>
                        <h6>{t("busTravel")}</h6>
                        <ul>
                            <li><a href="#">{t("allDestination")}</a></li>
                            <li><a href="#">{t("getIos")}</a></li>
                            <li><a href="#">{t("getAndroid")}</a></li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <h6>VB Trans Logistik</h6>
                        <ul>
                            <li><a href="#">{t("about")}</a></li>
                            <li><a href="#">{t("jobs")}</a></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <h4>{t("findUs")}</h4>
                        <ul className='social-menu d-flex align-items-center justify-content-end'>
                            <li><a href="#" className='facebook'>f</a></li>
                            <li><a href="#" className='twitter'><TwitterOutlined /></a></li>
                            <li><a href="#" className='instagram'><InstagramOutlined /></a></li>
                            <li><a href="#" className='google'><GooglePlusOutlined /></a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Main;
