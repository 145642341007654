import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { Button } from 'antd';
import apiService from "../../services/api.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";

const StripeCheckoutForm = () => {
    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();

    const passengers = useSelector((state) => state.cart.passengers)
    const cargo = useSelector((state) => state.cart.cargo.items)
    const sender = useSelector((state) => state.cart.cargo.sender)
    const receiver = useSelector((state) => state.cart.cargo.receiver)
    const trip = useSelector((state) => state.cart.trip)

    const type = useSelector((state) => state.search.type)

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(false);

    const {t} = useTranslation()

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            setIsLoading(true)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    setStatus(true)
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    setStatus(false)
                    break;
                default:
                    setMessage("Something went wrong.");
                    setStatus(false)
                    break;
            }
        });

        setTimeout(() => setIsLoading(false), 2500)
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `http://localhost:3000/payment/processing/`,
            }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        }
        else {
            setMessage("An unexpected error occurred.");
        }
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const getPassengers = async () => {
        setIsLoading(true);
        if (status && passengers) {
            try {
                const res = await apiService.passengerAdd({ passengers });
                if (res) {
                    const response = (await stripe.retrievePaymentIntent(clientSecret)).paymentIntent
                    const payment = await apiService.payTickets({
                        tickets: passengers.map((passenger) => ({ ticketId: passenger.ticketId })),
                        transactionId: response.id,
                        method: response.payment_method_types[0],
                        sum: response.amount / 100
                    })

                    if (payment.data.success) {
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 2500)
                        navigate("/payment/success")
                    }
                    else {
                        navigate("/payment/error")
                    }
                }
                else {
                    navigate("/payment/error")
                }
            } catch (error) {
                console.log(error)
                navigate("/payment/error")
            }
        }
    }
    const getCargo = async () => {
        setIsLoading(true);
        if (status && passengers) {
            try {
                const senderReceiver = await apiService.cargoPassengerAdd({ passengers: [{ ...sender, kind: "" }, { ...receiver, kind: "" }] });
                if (senderReceiver) {
                    const response = (await stripe.retrievePaymentIntent(clientSecret)).paymentIntent
                    const payment = await apiService.payCargo({
                        transactionId: response.id,
                        method: response.payment_method_types[0],
                        sum: response.amount / 100
                    })
                    if (payment) {
                        const cargoTickets = await apiService.cargoTicketAdd({
                            tickets: cargo.map((item) => ({
                                departureTripId: trip.departureTripId,
                                arrivalTripId: trip.arrivalTripId,
                                routeId: trip.routeId,
                                senderId: senderReceiver.data[0].id,
                                receiverId: senderReceiver.data[1].id,
                                paymentId: payment.data.id,
                                categoryId: item.type,
                                weight: item.weight,
                                height: item.height,
                                length: item.length,
                                width: item.width
                            }))
                        });
                        if (cargoTickets.data) {
                            setTimeout(() => {
                                setIsLoading(false);
                            }, 2500)
                            navigate("/payment/success")
                        }
                        else navigate("/payment/error")
                    }
                    else {
                        navigate("/payment/error")
                    }
                }
                else {
                    navigate("/payment/error")
                }
            } catch (error) {
                console.log(error)
                navigate("/payment/error")
            }
        }
    }
    useEffect(() => {
        type == "tickets" ? getPassengers() : getCargo()
        setTimeout(() => setIsLoading(false), 2500)
    }, [status])

    return (
        <>
            {isLoading
                ? <Loader loading={isLoading} />
                : (
                    <form id="payment-form" onSubmit={handleSubmit}>
                        <PaymentElement id="payment-element" options={paymentElementOptions} className='mb-4' />
                        <Button loading={isLoading || !stripe || !elements} id="submit" htmlType="submit" className='book-now-button d-flex justify-content-center align-items-center' type="default" shape="round" size={"large"}>
                            {/* Pay now */}
                            {t("pay")}
                        </Button>
                        {/* Show any error or success messages */}
                        {message && <h5 id="payment-message">{message}</h5>}
                    </form>
                )
            }
        </>
    );
}
export default StripeCheckoutForm;
