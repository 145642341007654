import SeatView from "./SeatView";
import {useCallback} from "react";
import icon_bus_seat_premium  from "../../../assets/bus/icon_bus_seat_premium@2x.png"
import icon_bus_seat_disability  from "../../../assets/bus/icon_bus_seat_disability@2x.png"
import {useDispatch} from "react-redux";
import {addSeat} from "../../../slices/cart";
import '../../../constants/constants.css';

const color = {
    seat: {
        status: {
            unavailable: {
                text: "#2F313D",
                background: "#6A6B71",
            },
            available: {
                text: "#2F313D",
                background: "#ffffff",
            },
            booked: {
                text: "#ffffff",
                background: "#126DC5",
            },
            selected: {
                text: "#ffffff",
                background: "var(--base-color-orange)",
            }
        },
        category: {
            standart: "#ffffff",
            premium: "#FFCB61",
            disability: "#00AA9D"
        }
    }
}
const BusObjectSeatView = (props) => {
    const dispatch = useDispatch();
    const source = useCallback(() => {
        switch (props.busSeatObject.category) {
            case "standart":
                return undefined
            case "premium":
                return icon_bus_seat_premium
            case "disability":
                return icon_bus_seat_disability
            default:
                return undefined
        }
    }, [props.busSeatObject.category])

    const colors = useCallback(() => {
        var colorSeat  = undefined
        var colorArmrest  = undefined
        var colorText  = undefined
        var colorIcon  = undefined

        if (props.selected) {
            colorSeat = color.seat.status.selected.background
            colorArmrest = color.seat.status.selected.background
            colorIcon = color.seat.status.selected.text
            colorText = color.seat.status.selected.text
        } else {
            switch (props.status) {
                case "unavailable":
                    colorSeat = color.seat.status.unavailable.background
                    colorArmrest = color.seat.status.unavailable.background
                    colorText = color.seat.status.unavailable.text
                    colorIcon = color.seat.status.unavailable.text
                    break;
                case "available":
                    colorSeat = color.seat.status.available.background
                    switch (props.busSeatObject.category) {
                        case "standart":
                            colorArmrest = color.seat.category.standart
                            colorIcon = color.seat.category.standart
                            break
                        case "premium":
                            colorArmrest = color.seat.category.premium
                            colorIcon = color.seat.category.premium
                            break
                        case "disability":
                            colorArmrest = color.seat.category.disability
                            colorIcon = color.seat.category.disability
                            break
                    }
                    colorText = color.seat.status.available.text
                    break;
                case "booked":
                    colorSeat = color.seat.status.booked.background
                    colorArmrest = color.seat.status.booked.background
                    colorText = color.seat.status.booked.text
                    colorIcon = color.seat.status.booked.text
                    break;
            }
        }
        return { seat: colorSeat, armrest: colorArmrest, icon: colorIcon, text: colorText }
    }, [props.busSeatObject.category, props.selected, props.status])

    return (
        <div
            className={'BusObjectSeatView'}
            style={{ alignItems: "center", justifyContent: "center", ...props.style}}
            onClick={()=> {
                if(props.status !== "available") return;
                props.onPress()
            }}
            disabled={!(props.status === "available")}
        >
            <SeatView
                style={{ height: 232.0 * 100.0 / 340.0 + "%", cursor: 'pointer'}}
                direction={props.busSeatObject.direction}
                seatColor={colors().seat}
                armrest={props.busSeatObject.armrest}
                armrestColor={colors().armrest}
                number={props.busSeatObject.number}
                numberColor={colors().text}
                icon={source()}
                iconColor={colors().icon} />
        </div>
    )

}
export default BusObjectSeatView
