import React from 'react'
import SliderMain from '../slider/SliderMain'
import "../resendticket/resendticket.css"
import "./transaction.css"
import busstop from "../../assets/img/bus_stop_image.png"
import bus from "../../assets/img/bus_image.png"
import { ReactComponent as SuccessImage } from "../../assets/img/icon_success.svg"
import { Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TransactionSuccess = () => {
  const { t } = useTranslation()
  return (
    <>
      <SliderMain />
      <Container className='resend-main'>
        <div className='resend-ticket-info'>
          <div className='resend-ticket-info-wrapper'>
            <div className='d-flex flex-column justify-content-end transaction-result-wrapper'>
              <div className='d-flex align-items-center justify-content-center  position-relative'>
                <SuccessImage height={36} className="transaction-result-icon" />
                <div className='transaction-result-border-b text-center'>
                  <h3>
                    {t("transactionSuccess")}
                    {/* successful transaction */}

                  </h3>
                </div>
              </div>
              <div className='align-self-end'>
                <p className='success'>
                  {t("ticketDetailsRight")}
                  {/* Your ticket details were sent to your
                  <br /> email address. */}
                </p>
              </div>
            </div>
            <div className='resend-image-container'>
              <Image src={bus} className='resend-image-bus' />
              <Image src={busstop} className='resend-image' />
            </div>
            <div className='transaction-result-btn-container'>
              <Link to={"/"} className='transaction-result-btn'>
                {/* Return to Home Page */}
                {t("returnHome")}
                </Link>
            </div>
          </div>

        </div>
      </Container></>
  )
}

export default TransactionSuccess