import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
} from '@stripe/react-stripe-js';
import apiService from "../../services/api.service";
import StripeCheckoutForm from "./StripeCheckoutForm";
import PayPalCheckoutForm from "./PayPalCheckoutForm";
import { useSelector } from 'react-redux'

const stripePromise = loadStripe('pk_test_FQRkJ2sDhk0hjqOfSpsmoMcc');

const Payment = () => {
    const [clientSecret, setClientSecret] = useState("");
    const payment = useSelector((state) => state.cart.payment);
    const amount = useSelector((state) => state.cart.sum)
    const roundedAmount = amount.toFixed(2)
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        if (payment === "card") {
            if (!clientSecret) {
                apiService.paymentIntent({
                    amount: roundedAmount,
                    currency: 'eur'
                }).then((resp) => {
                    if (resp.status === 200)
                        setClientSecret(resp.data.clientSecret)
                });
            }
        }
    }, [payment]);
    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#ee703d',
            colorText: '#094267',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <>
            {payment === 'card' ? (
                <>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <StripeCheckoutForm />
                        </Elements>
                    )}
                </>
            ) : <PayPalCheckoutForm />}
        </>
    )

};
export default Payment;
