import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from "../services/api.service";

export const fetchUsed = createAsyncThunk('cart/fetchUsed', async (data) => {
    const response = await apiService.fetchUsed(data);
    return response.data
})

export const reserveSeat = createAsyncThunk('cart/reserveSeat', async (data) => {
    const response = await apiService.reserveSeat(data);
    return response.data
})
export const unreserveSeat = createAsyncThunk('cart/unreserveSeat', async (data) => {
    const response = await apiService.unreserveSeat(data);
    return response.data
})
export const fetchBusLayout = createAsyncThunk('cart/fetchBusLayout', async (data) => {
    const response = await apiService.fetchBusLayout(data);
    return response.data
})


const initialState = {
    trip: null,
    seats: [],
    reserved: [],
    usedSeats: [],
    passengers: [],
    cargo: {
        items: [{
            type: 1,
            weight: 0,
            width: 0,
            length: 0,
            height: 0
        }],
        sender: {
            name: '',
            surname: '',
            gender: 'male',
            birthday: '',
            passport: '',
            phone: '',
            email: '',
            subscribe: false,
        },
        receiver: {
            name: '',
            surname: '',
            gender: 'male',
            birthday: '',
            passport: '',
            phone: '',
            email: '',
            subscribe: false,
        }
    },

    endSessionTime: null,
    busLayout: null,
    payment: '',
    sum: 0,
    step: 1,
    status: 'idle',
    type: 'tickets',
    error: null,
}


const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        editPassenger(state, action) {
            let pass = state.passengers.find(el => el.ticketId === action.payload.ticketId);
            pass[action.payload.key] = action.payload.value;
        },
        addPassenger(state, action) {
            state.passengers.push({
                name: '',
                surname: '',
                gender: 'Male',
                birthday: '',
                passport: '',
                phone: '',
                email: '',
                kind: 'Mature',
                subscribe: 'true',
                ticketId: action.payload,
                bagageCount: 0
            })
        },
        removePassenger(state, action) {
            let tempPassengers = state.passengers.filter(el => el.ticketId !== action.payload.id);
            state.passengers = tempPassengers
        },
        increaseStep(state, action) {
            state.step = state.step + 1;
        },

        decreaseStep(state, action) {
            let tempStep = state.step - 1;
            state.step = tempStep < 1 ? 1 : tempStep;
        },
        setTrip(state, action) {
            state.trip = action.payload
            state.endSessionTime = Math.floor(Date.now() / 1000) + 6000;
        },
        addSeat(state, action) {
            state.seats.push(action.payload)
        },
        removeSeat(state, action) {
            let tempSeats = [...state.seats];
            tempSeats.splice(action.payload.index, 1);
            state.seats = tempSeats;
        },
        removeReserve(state, action) {
            let tempSeats = state.reserved.filter(el => el.id !== action.payload.id);
            state.reserved = tempSeats
        },
        setTicketType(state, action) {
            state.type = action.payload
        },
        decreaseCargoItems(state, action) {
            if (state.cargo.items.length === 1) return;

            let tempItems = [...state.cargo.items];
            tempItems.splice(tempItems.length - 1, 1);
            state.cargo.items = tempItems;
        },
        increaseCargoItems(state, action) {
            state.cargo.items.push({
                type: 1,
                weight: 0,
                width: 0,
                length: 0,
                height: 0
            })
        },
        editCargoItem(state, action) {
            state.cargo.items[action.payload.index][action.payload.key] = action.payload.value;
        },
        editSender(state, action) {
            state.cargo.sender[action.payload.key] = action.payload.value;
        },
        editReceiver(state, action) {
            state.cargo.receiver[action.payload.key] = action.payload.value;
        },
        setPaymentType(state, action){
            state.payment = action.payload
        },
        setPaymentAmount(state, action){
            state.sum = action.payload
        }

    },
    extraReducers(builder) {
        builder
            .addCase(fetchUsed.fulfilled, (state, action) => {
                state.usedSeats = action.payload
            })
            .addCase(reserveSeat.fulfilled, (state, action) => {
                state.reserved = state.reserved.concat(action.payload)
            })
            .addCase(unreserveSeat.fulfilled, (state, action) => {

            })
            .addCase(fetchBusLayout.fulfilled, (state, action) => {
                state.busLayout = action.payload
            })
    },
})


export default cartSlice.reducer

export const {
    setTicketType,
    increaseStep,
    decreaseStep,
    addSeat,
    removeSeat,
    setTrip,
    removeReserve,
    decreaseCargoItems,
    increaseCargoItems,
    editCargoItem,
    addPassenger,
    removePassenger,
    editPassenger,
    editReceiver,
    editSender,
    setPaymentType,
    setPaymentAmount
} = cartSlice.actions

export const getPassengerByTicketId = (state, ticketId) => state.cart.passengers.find(pass => pass.ticketId === ticketId)

// export const selectPostById = (state, postId) =>
//     state.posts.posts.find((post) => post.id === postId)

