import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from "antd";
import { ArrowRightOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getPassengerByTicketId, increaseStep, setPaymentAmount } from "../../slices/cart";
import { useTranslation } from 'react-i18next';

const formatMoney = (value) => {
    return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
        value,
    )
}

const BookCartItemSeat = ({ seat, trip }) => {
    let price = trip.prices.find(el => el.key === seat.seat.category)
    const passenger = useSelector(state => getPassengerByTicketId(state, seat.id))
    if (!price) return null;

    const bagagePrice = () => {
        let element = trip.baggagePrices.find(el => el.key === seat.seat.category);
        return formatMoney(parseFloat(element.price_amount).toFixed(2) * passenger.bagageCount)
    }

    return (
        <>
            <Row>
                <Col md={8}>
                    <h5 style={{ textTransform: 'capitalize' }}>{seat.seat.category} seat</h5>
                </Col>
                <Col md={4}>
                    <h5>{formatMoney(price.price_amount)}</h5>
                </Col>
            </Row>
            {passenger?.bagageCount > 0 && (
                <Row>
                    <Col md={8}>
                        <h5 style={{ textTransform: 'capitalize' }}>{passenger.bagageCount} Extra Luggage</h5>
                    </Col>
                    <Col md={4}>
                        <h5>{bagagePrice()}</h5>
                    </Col>
                </Row>
            )}
        </>
    );
}
const BookCartItemCargo = ({ item, trip, index }) => {
    let price = trip?.prices.find(el => el.id === item.type).price_amount;
    const { t } = useTranslation()
    if (!price) return null;
    return (
        <>
            <Row>
                <Col md={8}>
                    <h5 style={{ textTransform: 'capitalize' }}>{t("cargo")} {index + 1}: {item.weight} kg ({item.type === 2 ? t("solid") : t("fragile")})</h5>
                </Col>
                <Col md={4}>
                    <h5>{formatMoney(item.weight > 0 ? price : 0)}</h5>
                </Col>
            </Row>
        </>
    );
}

const TicketCart = ({ trigger }) => {
    const dispatch = useDispatch()
    const seats = useSelector(state => state.cart.reserved);
    const trip = useSelector(state => state.cart.trip);
    const passengers = useSelector(state => state.cart.passengers);
    const { t } = useTranslation()

    const reservedSeatArr = seats.map(seat => trip.prices.find(item => seat.seat.category === item.key).price_amount);
    const baArr = seats.map((seat) =>
        trip.baggagePrices.find(item => seat.seat.category === item.key).price_amount * (passengers.find(el => el.ticketId === seat.id)?.bagageCount ?? 0)
    );
    let totalSum = (reservedSeatArr.reduce((a, b) => +a + +b, 0) + baArr.reduce((a, b) => +a + +b, 0));
    useEffect(() => { dispatch(setPaymentAmount(totalSum)) }, [totalSum])
    return (<>
        <div className={seats.length <= 0 ? 'cart-seat-info d-flex justify-content-center align-items-center' : 'cart-seat-info'}>
            {seats.length > 0 ?
                (
                    <>
                        {seats.map(seat => <BookCartItemSeat key={seat.seatId} trip={trip} seat={seat} />)}
                    </>

                )
                : <h4 className={`${trigger ? "book-trigger-animation" : null}`}>
                    {t("noSeatsSelected")}
                    {/* Please, select seats */}
                </h4>
            }
        </div>
        <div className='total-ticket-price'>
            <h3 className='d-flex justify-content-between align-items-center'>{t("total")} <span>{totalSum ? formatMoney(totalSum) : formatMoney(0)}</span></h3>
        </div>
    </>)
}
const CargoCart = () => {
    const dispatch = useDispatch()
    const cargoItems = useSelector(state => state.cart.cargo.items);
    const trip = useSelector(state => state.cart.trip);
    const { t } = useTranslation()

    const reservedSeatArr = cargoItems.map(el => trip?.prices.find(item => el.type === item.id).price_amount * el.weight);
    const totalSum = reservedSeatArr.reduce((a, b) => +a + +b, 0)
    useEffect(() => { dispatch(setPaymentAmount(totalSum)) }, [totalSum])
    return (
        <>
            <div className={cargoItems.length <= 0 ? 'cart-seat-info d-flex justify-content-center align-items-center' : 'cart-seat-info'}>
                {cargoItems.length > 0 ?
                    (
                        <>
                            {cargoItems.map((item, key) => <BookCartItemCargo key={key} index={key} trip={trip} item={item} />)}
                        </>

                    )
                    : <h4>Please, complete form</h4>
                }
            </div>
            <div className='total-ticket-price'>
                <h3 className='d-flex justify-content-between align-items-center'>{t("total")} <span>{totalSum ? formatMoney(totalSum) : formatMoney(0)}</span></h3>
            </div>
        </>
    )
}

const BookCart = ({ nextStep, trigger }) => {
    const searchType = useSelector(state => state.search.type);
    const step = useSelector((state) => state.cart.step)
    const { t } = useTranslation()
    return (
        <div style={{ border: '1px solid #094267', borderRadius: '5px' }}>
            <div style={{ padding: '20px', textAlign: 'center' }}><h5 style={{ marginBottom: '0' }}>
                {/* Your booking details */}
                {t("bookingDetails")}
            </h5></div>

            {searchType === 'tickets' && <TicketCart trigger={trigger} />}
            {searchType === 'cargo' && <CargoCart />}
            {step < 4 ? <div className='cart-next-btn text-center'>
                <Button
                    className='book-now-button d-flex justify-content-center align-items-center'
                    onClick={nextStep}
                    type="default"
                    htmlType='submit'
                    shape="round"
                    size={"large"}>
                    {step == 3 && <ShoppingOutlined style={{ fontSize: "2rem" }} type='message' />}
                    {step == 3 ? t("payNow") : t("next")}
                    {step < 3 && <ArrowRightOutlined />}
                </Button>
            </div> : null}

        </div>
    );
}
export default BookCart;
