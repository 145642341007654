import {useCallback} from "react";
import image_bus_object_exit from "../../../assets/bus/image_bus_object_exit@2x.png"
import IconTint from 'react-icon-tint';
const BusObjectExitView = (props) => {
    const rotate = useCallback(() => {
        switch (props.direction) {
            case "left":
                return "-90deg"
            case "right":
                return "90deg"
            case "bottom":
                return "180deg"
            default:
                return "0deg"
        }
    }, [props.direction])

    return (
        <div className={'BusObjectExitView'} style={{ alignItems: "center", justifyContent: "center", ...props.style}}>
            <IconTint src={image_bus_object_exit} color="#4A4C52" style={{ width: '80%',  transform: 'rotate('+rotate() +')' }} />
        </div>
    )
}
export default BusObjectExitView;
