import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Radio, Space, Switch } from 'antd';
import visa from '../../assets/img/card_visa@3x.png';
import mastercard from '../../assets/img/card_master@3x.png';
import maestro from '../../assets/img/card_maestro@3x.png';
import paypal from '../../assets/img/card_paypal@3x.png';
import { useTranslation } from 'react-i18next';

const PaymentSwitcher = ({ onChange, check, setCheck, payment, trigger }) => {
    const { t } = useTranslation()
    const pMethods = [
        {
            id: 0,
            value: "card",
            label: t("cCard"),
            description: t("cCardDescription"),
            options: [
                {
                    id: 0,
                    value: "Visa",
                    img: visa
                },
                {
                    id: 1,
                    value: "MasterCard",
                    img: mastercard
                },
                {
                    id: 2,
                    value: "Maestro",
                    img: maestro
                }
            ]
        },
        {
            id: 1,
            value: "paypal",
            label: "PayPal",
            img: paypal
        }
    ];

    return (
        <Row className='payment-row'>
            <Col md={12} style={{ paddingRight: '0' }}>
                <div className='outer-agreement'>
                    <h4>
                        {/* Agreement */}
                        {t("agreement")}
                    </h4>
                    <div className='d-flex w-100 justify-content-between align-items-center'>
                        <p className='mb-0'>
                            {/* I declare to have read the Privacy Policy and I agree <br /> to the T&C of Booking and T&C of Carriage */}
                            {t("agreementText")}
                        </p>
                        <Switch size="default" className={`sr-switch ${trigger && !check ? "bg-danger book-trigger-animation" : null}`} checked={check} onChange={() => setCheck(!check)} />
                    </div>
                </div>
                <div>
                    <h4>
                        {t("payMethod")}
                        {/* Payment method */}
                    </h4>
                    <div className={`${trigger && !payment ? "shaker" : null}`}>
                        <Radio.Group onChange={onChange} value={payment}>
                            <Space direction="vertical">
                                {pMethods.map((payment, i) =>
                                    <Radio key={payment.id} value={payment.value} id='radiokey'>
                                        <div className='d-flex align-items-center gap-2'>
                                            <p className='pvalue'>{payment.label}</p>
                                            {payment?.options
                                                ? payment?.options.map((option) => <img alt={option.value} key={option.id} src={option.img} className='cargo-radio-img' />)
                                                : <img alt={payment.value} key={payment.id} src={payment.img} className='cargo-radio-img' />
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <p id='pdesc' className={i !== pMethods.length - 1 ? 'outer-custom-border' : null} >{payment.description}</p>
                                        </div>
                                    </Radio>
                                )}
                            </Space>
                        </Radio.Group>
                    </div>
                </div>
            </Col>
        </Row >
    )
}

export default PaymentSwitcher;