import {useEffect, useRef, useState} from "react";

const BusObjectStairsView = (props) => {
    const [size, setSize] = useState({ width: 0, height: 0 })
    const stairs = useRef();
    const flexDirection = (props.direction === "left" || props.direction === "right") ? "row" : "column"
    const count  = Math.floor(((props.direction === "left" || props.direction === "right") ? size.width : size.height) / 10.0)
    const internStyle = (props.direction === "left" || props.direction === "right") ? { width: 1 } : { height: 1 }
    useEffect(() => {
        setSize({ width: stairs.current.clientWidth, height: stairs.current.clientHeight })
    }, [])
    return (
        <div
            ref={stairs}
            className={'BusObjectStairsView'}
            style={{ flexDirection: flexDirection, backgroundColor: "#4A4C52", justifyContent: "space-evenly", ...props.style}}
            // onLayout={(event) => { setSize({ width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height }) }}
        >
            {(count > 1) ?
                Array.from(Array(count - 1)).map((_, index) => <div key={index} style={{ ...internStyle, backgroundColor: "#6A6B71"}} />)
                :
                null
            }
        </div>
    )
}
export default BusObjectStairsView;
