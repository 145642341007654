import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import BusFloor from "../../components/bus/BusFloor";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusLayout, fetchUsed } from "../../slices/cart";
import './book.css';
import Seo from '../seo/Seo'

const BookSeat = ({ setSubmittable }) => {
    const dispatch = useDispatch();
    const busLayout = useSelector(state => state.cart.busLayout);
    const trip = useSelector(state => state.cart.trip);
    const passengers = useSelector((state) => state.cart.passengers);

    useEffect(() => {
        if (trip) {
            dispatch(fetchBusLayout(trip.bus.layoutId));
            dispatch(fetchUsed({
                routeId: trip.routeId,
                departureTripId: trip.departureTripId,
                arrivalTripId: trip.arrivalTripId
            }))
        }
    }, []);

    useEffect(() => {
        if (passengers.length) {
            setSubmittable(true)
        } else {
            setSubmittable(false)
        }
    }, [passengers])


    if (!busLayout) return null;

    return (
        <>
            <Seo title='BookSeats page React Helmet!'
                 description='BookSeats page React Helmet.'
                 name='VBus'
                 type='article'
            />
            <Row>
                {busLayout.floors.map(floor => (
                    <Col md={busLayout.floors.length > 1 ? 6 : 12} key={floor.name}>
                        <h5 className='bus-floor-type'>Bus {floor.name}</h5>
                        <BusFloor floor={floor} />
                    </Col>
                ))}
            </Row>
        </>
    );
}
export default BookSeat;
