import {useCallback} from "react";
import IconTint from "react-icon-tint";

const SeatView = (props) => {
    const aspectRatio = useCallback(() => {
        return (props.armrest === "left" || props.armrest === "right") ? 118 / 100 : ((props.armrest === "both") ? 136 / 100 : 1)
    }, [props.armrest])

    const rotate = useCallback(() => {
        switch (props.direction) {
            case "left":
                return { value: "-90deg", revert: "90" }
            case "right":
                return { value: "90deg", revert: "-90" }
            case "bottom":
                return { value: "180deg", revert: "-180" }
            default:
                return { value: "0deg", revert: "0" }
        }
    }, [props.direction])

    return (
        <div style={{ display:'flex', flexDirection: "row", aspectRatio: aspectRatio(), alignItems: "flex-end", justifyContent: "space-between", transform: 'rotate('+rotate().value +')', ...props.style}}>
            {(props.armrest === "left" || props.armrest === "both") ?
                <div style={{ width: 26 * 100 / (props.armrest === "both" ? 310 : 271) + "%", height: 141 * 100 / 232 + "%", backgroundColor: props.armrestColor ?? "#000000", borderRadius: 500 }} />
                :
                null
            }
            <svg viewBox={"0 0 100 100"} style={{ aspectRatio: 1, height: "100%" }}>
                <path d={"M 18 0 L 82 0 Q 100 0 100 18 L 100 91 Q 100 100 91 100 L 9 100 Q 0 100 0 91 L 0 18 Q 0 0 18 0 Z"} fill={props.seatColor?.toString() ?? "#000000"} />
                <g width={100} height={100}  transform={'rotate('+rotate().revert +' 50 50)'}>
                    {props.number ?
                        <text x="50" y={props.icon  ? 30 : 50}
                              fill={props.numberColor ? props.numberColor : "#ffffff"}
                              alignmentBaseline="middle"
                              fontSize="24"
                              textAnchor="middle" style={{ height: 90.0 * 100.0 / 232.0 + "%"}}>{props.number}</text>
                        :
                        null
                    }
                    {props.icon ?
                        <image  x="35" y="50"  href={props.icon} fill={props.iconColor ? props.iconColor : "#ffffff"} style={{ aspectRatio: 1, height: 90.0 * 100.0 / 232.0 + "%", tintColor: props.iconColor ?? "#ffffff" }} />
                        :
                        null
                    }
                </g>
            </svg>
            {(props.armrest === "right" || props.armrest === "both") ?
                <div style={{ width: 26 * 100 / (props.armrest === "both" ? 310 : 271) + "%", height: 141 * 100 / 232 + "%", backgroundColor: props.armrestColor ?? "#000000", borderRadius: 500 }} />
                :
                null
            }
        </div>
    )
}
export default SeatView;
