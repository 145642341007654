import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Button, Form, Input } from 'antd';

import './footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    const onSubscribe = (values) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email))
            console.log('Subscribe:', values);
        else console.log(false)
    };

    const { t } = useTranslation()
    return (
        <div className='footer-block'>
            <div className='subscribe-block d-flex align-items-center justify-content-center'>
                <Container>
                    <Row>
                        <Col md={{ span: 9, offset: 3 }} className=''>
                            <Row style={{ height: '110px' }} className='align-items-center custom-border-el'>
                                <Col md={6}>
                                    <h6>
                                        {t("subscribe")}
                                        {/* Subscribe */}
                                    </h6>
                                    <p style={{ marginBottom: '0', fontSize: '14px' }}><i>
                                        {t("receiveFribies")}
                                        {/* To receive the latest fribies in your inbox */}
                                        </i></p>
                                </Col>
                                <Col md={6} style={{ padding: '0' }} className='d-flex align-items-center justify-content-end'>
                                    <Form form={form} name="subscribe" layout="inline" onFinish={onSubscribe}>
                                        <Form.Item className='form-imput' name="email">
                                            <Input placeholder={t("yourEmail")} type='email' />
                                        </Form.Item>
                                        <Form.Item className='form-button' shouldUpdate>
                                            {() => (
                                                <Button type="primary" htmlType="submit">
                                                    {t("subscribe")}
                                                    {/* Subscribe */}
                                                    </Button>
                                            )}
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-menu-list d-flex align-items-center">
                <Container>
                    <Row>
                        <Col>
                            <ul className='d-flex align-items-center'>
                                <li><a href="#">{t("privacy")}</a></li>
                                <li><a href="#">{t("passRights")}</a></li>
                                <li><a href="#">{t("legal")}</a></li>
                            </ul>
                        </Col>
                        <Col>
                            <p className='text-end'>© 2023 VB Trans Logistik</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
export default Footer;
