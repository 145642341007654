import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Select, Button, DatePicker, Divider, Typography } from 'antd';
import { SearchOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLocations, getAllLocations } from "../../slices/locations";
import { useLocation, useNavigate } from "react-router-dom";
import {
    clearArrivals,
    clearDepartures,
    clearResult,
    fetchArrival,
    fetchDeparture,
    fetchSearch,
    setDate,
    setFrom,
    setStatus,
    setTo,
} from '../../slices/search'
import './searchBox.css';
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar.svg';
import dayjs from "dayjs";
import { t } from 'i18next';

const { Title } = Typography;

const customLabel = (item, elem) => {
    return (
        <p className='custom-label-name'>
            <strong className='label-name-style' style={{ maxWidth: '80%' }}>{item.name}</strong>
            <span style={{ color: '#898B91' }}> {elem.name}</span>
        </p>
    )
}

const customArrivalLabel = (loc) => {
    return (
        <p className='custom-label-name'>
            <strong className='label-name-style' style={{ maxWidth: '80%' }}>{loc.name}</strong>
            <span style={{ color: '#898B91' }}> {loc.country.name}</span>
        </p>
    )
}

const SearchBoxMainTickets = () => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false);

    const navigate = useNavigate();
    const locations = useSelector((state) => state.locations.locations);

    const location = useLocation().pathname.split("/")[1]

    const itemFrom = useSelector(state => state.search.from);
    const itemTo = useSelector(state => state.search.to);
    const itemDate = useSelector(state => state.search.date);
    const itemStatus = useSelector(state => state.search.status);
    const arrivalResult = useSelector(state => state.search.arrivalResult);
    const departureResult = useSelector(state => state.search.departureResult);

    const [toCheck, setToCheck] = useState(itemTo)
    const [fromCheck, setFromCheck] = useState(itemFrom)

    useEffect(() => {
        if (itemStatus !== 'idle') {
            clickSearch();
        }
    }, [itemStatus]);

    useEffect(() => {
        if (!locations.length) {
            dispatch(fetchLocations());
        }
    }, [locations]);

    const switchFromTo = () => {
        let tempitemFrom;
        let tempitemTo;
        if (location == "search") {
            tempitemFrom = fromCheck;
            tempitemTo = toCheck;
        } else {
            tempitemFrom = itemFrom;
            tempitemTo = itemTo;
        }

        if (getArrivalOptions().some((el) => el.value == tempitemFrom) && getDepartureOptions().some((el) => el.value == tempitemTo)) {
            dispatch(setFrom(tempitemTo))
            setFromCheck(tempitemTo)
            dispatch(fetchArrival({ departureId: tempitemTo }))
            dispatch(setTo(tempitemFrom))
            setToCheck(tempitemFrom)
            dispatch(fetchDeparture({ arrivalId: tempitemFrom }))
        }
        else {
            dispatch(setFrom(tempitemTo))
            setFromCheck(tempitemTo)
            dispatch(fetchArrival({ departureId: tempitemTo }))
            dispatch(setTo(null))
            setToCheck(null)
            dispatch(clearDepartures([]))
        }
        dispatch(clearResult([]))
    }

    const getOptions = () => {
        if (!locations) return [];
        let tempArray = [];
        // console.log(locations)
        locations.forEach(elem => {
            elem.cities.map(item => tempArray.push({ groupLabel: item.name + ' ' + elem.name, value: item.id, label: customLabel(item, elem) }))
        })
        return tempArray;
    }

    const getArrivalOptions = () => {
        if (!arrivalResult) return [];
        let tempArray = [];
        arrivalResult.forEach(loc => {
            tempArray.push({ groupLabel: loc.name, value: loc.id, label: customArrivalLabel(loc) })
        })
        return tempArray
    }
    const getDepartureOptions = () => {
        if (!departureResult) return [];
        let tempArray = [];
        departureResult.forEach(loc => {
            tempArray.push({ groupLabel: loc.name, value: loc.id, label: customArrivalLabel(loc) })
        })
        return tempArray
    }

    const onClickSearch = () => {
        const departure = locations.map((loc) => { let notNullCities = loc.cities.filter((city) => city.id == itemFrom); return notNullCities && notNullCities[0]?.name })
        const arrival = locations.map((loc) => { let notNullCities = loc.cities.filter((city) => city.id == itemTo); return notNullCities && notNullCities[0]?.name })
        const dep = departure.filter((dep) => dep !== undefined);
        const arr = arrival.filter((arr) => arr !== undefined);
        const departureToSend = dep[0]?.replace(/^[, ]+|[, ]+$|[, ]+/g, "_").trim()
        const arrivalToSend = arr[0]?.replace(/^[, ]+|[, ]+$|[, ]+/g, "_").trim()
        if (location == "search" && itemStatus != "idle") {
            setCheck(false)
            if (toCheck) {
                dispatch(setTo(toCheck))
            }
            if (fromCheck) {
                dispatch(setFrom(fromCheck))

            }
        } else {
            if (!itemTo || !itemFrom) {
                setCheck(true);
                return;
            }
            else {
                setCheck(false)
                dispatch(setStatus('search'))
                navigate(`/search/${itemFrom + "_" + departureToSend}/${itemTo + "_" + arrivalToSend}`)
            }
        }
    }

    const clickSearch = () => {
        if (location == "search") {
            if ((!fromCheck || !toCheck)) return;
        }
        else {
            if ((!itemTo || !itemFrom)) return;
        }
        dispatch(fetchSearch({
            departureId: itemFrom,
            arrivalId: itemTo,
            date: itemDate || Date.now() + 10000
        }))
    }

    let currentDay = dayjs().startOf('date');

    const disabledDate = (current) => {
        return current && current < currentDay;
    };
    const timeNow = new Date()
    const hours = timeNow.getHours()
    const minutes = timeNow.getMinutes()

    function handleDate(item) {
        let tempTime;
        if (item) {
            if (item.$H == hours && item.$m == minutes) {
                tempTime = item.add(15, "minutes")
            }
            else if (item.$H == 0 && item.$m == 0) {
                tempTime = item.add(hours, "hours").add(minutes, "minutes").add(15, "minutes")
            }
            else {
                tempTime = item
            }
            const timeToSend = tempTime?.$d.toString()
            dispatch(setDate(timeToSend));
        }
        else {
            console.log("error")
        }
    }
    return (
        <Container className='search-box-block'>
            <Row>
                <Col md={8} className='d-flex flex-column'>
                    <div className={` ${check && !itemFrom && "block-animation"}`}>
                        <Title level={5} style={check && !itemFrom ? { color: "red" } : null}>
                            {t("from")}
                            {/* From */}
                        </Title>
                        <Select
                            showSearch
                            options={departureResult?.length && (itemTo || toCheck) ? getDepartureOptions() : !departureResult?.length && (itemTo || toCheck) ? [] : getOptions()}
                            onChange={(item) => {
                                if (location == "search") {
                                    setFromCheck(item);
                                    dispatch(fetchArrival({
                                        departureId: item
                                    }))
                                } else {
                                    dispatch(setFrom(item))
                                    dispatch(fetchArrival({
                                        departureId: item
                                    }))
                                    dispatch(clearResult([]))
                                }
                            }}
                            filterOption={(inputValue, option) =>
                                option.groupLabel.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            placeholder={t("selectDir")}
                            className={`${check && !itemFrom && "block-color"} `}
                            suffixIcon={null}
                            defaultValue={itemFrom}
                            value={location == "search" ? fromCheck : itemFrom}
                        // value={itemFrom}
                        />
                    </div>

                    <Divider orientation="right" plain style={{ marginTop: '0', marginBottom: '0' }}>
                        <Button
                            shape="round"
                            className='change-dir-btn d-flex align-items-center'
                            onClick={switchFromTo}
                        >
                            <span className='custom-arrow-up'></span>
                            <span className='custom-arrow-down'></span>
                        </Button>
                    </Divider>
                    <div className={` ${check && !itemTo && "block-animation"}`}>
                        <Title level={5} style={check && !itemTo ? { color: "red" } : null}>
                            {t("to")}
                            {/* To */}
                        </Title>
                        <Select
                            showSearch
                            options={arrivalResult?.length && (fromCheck || itemFrom) ? getArrivalOptions() : !arrivalResult?.length && (itemFrom || fromCheck) ? [] : getOptions()}
                            onChange={(item) => {
                                if (location == "search") {
                                    setToCheck(item);
                                    dispatch(fetchDeparture({
                                        arrivalId: item
                                    }))
                                } else {
                                    dispatch(setTo(item))
                                    dispatch(fetchDeparture({
                                        arrivalId: item
                                    }))
                                    dispatch(clearResult([]))
                                }
                            }}
                            filterOption={(inputValue, option) =>
                                option.groupLabel.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            placeholder={t("selectDir")}
                            className={`${check && !itemTo && "block-color"} `}
                            suffixIcon={null}
                            defaultValue={itemTo}
                            value={location == "search" ? toCheck : itemTo}
                        // value={itemTo}
                        /></div>
                </Col>
                <Col md={4} className='d-flex flex-column justify-content-between'>
                    <div className='d-flex flex-column'>
                        <Title level={5} style={{ fontSize: '14px', marginBottom: '0' }}>
                            {/* Departure */}
                            {t("departure")}
                        </Title>
                        <DatePicker
                            disabledDate={disabledDate}
                            className='custom-datepicker-style'
                            defaultValue={itemDate ? dayjs(dayjs(itemDate), 'YYYY-MM-DD') : null}
                            format={'YYYY-MM-DD'}
                            onChange={handleDate}
                            placeholder={t("selectDate")}
                            suffixIcon={<CalendarIcon height={22} width={22} />}
                            nextIcon={<CaretRightOutlined style={{ color: '#ffffff' }} />}
                            prevIcon={<CaretLeftOutlined style={{ color: '#ffffff' }} />}
                        />
                    </div>
                    <Button className='search-button' type="primary" onClick={
                        () => onClickSearch()
                    }>
                        <div className=' position-relative'>
                            {t("search")}
                            <SearchOutlined className='search-icon' />
                        </div>
                        {/* Search */}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
export default SearchBoxMainTickets;
