import SeatView from "./SeatView";

const color = {
    seat: {
        status: {
            unavailable: {
                text: "#2F313D",
                background: "#6A6B71",
            },
            available: {
                text: "#2F313D",
                background: "#ffffff",
            },
            booked: {
                text: "#ffffff",
                background: "#126DC5",
            },
            selected: {
                text: "#ffffff",
                background: "#F1592A",
            }
        },
        category: {
            standart: "#ffffff",
            premium: "#FFCB61",
            disability: "#00AA9D"
        }
    }
}

const BusObjectDriverView = (props) => {
    return (
        <div className={'BusObjectDriverView'} style={props.style}>
            <div style={{ flex: 1 }}>
                <svg height={"100%"} width={"100%"} viewBox={"0 0 100 50"}>
                    <rect x={"25"} y={"0"} width={"50"} height={"10"} rx={"5"} ry={"5"} fill={color.seat.status.unavailable.background.toString()} />
                    <ellipse cx={"50"} cy={"30"} rx={"40"} ry={"8"} stroke={color.seat.status.unavailable.background.toString()} strokeWidth={"10"} />
                </svg>
            </div>
            <div style={{ aspectRatio: 1, alignItems: "center", justifyContent: "center" }}>
                <SeatView style={{ height: 232.0 * 100.0 / 340.0 + "%" }} direction={"top"} seatColor={color.seat.status.unavailable.background} armrest={"none"} />
            </div>
        </div>
    )
}
export default BusObjectDriverView;
